html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

input:focus {
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: currentColor;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

image {
  display: block;
  max-width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;
}

.container {
  overflow: hidden;
}

@media screen and (max-width: 1000px) {
  .container__two {
    max-width: 425px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
}

@media screen and (min-width: 1000px) {
  .container {
    padding: 0 0 0px 80px;
    // max-width: 1240px;
    // width: 100%;
    // margin: 0 auto;
  }

  .container__two {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    // padding: 0 50px;
  }

  .main {
    padding-bottom: 220px;
  }

  #root {
    position: relative;
    min-height: 100vh;
  }
}
@import "./_variables.scss";
@import "./header.scss";
@import "./footer.scss";
@import "./about.scss";
@import "./title.scss";
@import "./delivery.scss";
@import "./blog.scss";
@import "./label.scss";
@import "./performer.scss";
@import "./cassette.scss";
@import "./vinyl.scss";
@import "./release.scss";
@import "./sort.scss";
@import "./productsList.scss";
@import "./hero.scss";
@import "./admin.scss";
@import "./cart.scss";
@import "./modal.scss";
@import "./processing.scss";
@import "./pagination.scss";
@import "./search.scss";
@import "./loader.scss";
@import "./order.scss";

body {
  background-color: $black;
  margin: 0;
  font-family: "Onest", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.toast__success {
  background-color: $black2 !important;
  color: $green !important;
  border-radius: 0 !important;
}

.toast__error {
  background-color: $black2 !important;
  color: $red !important;
  border-radius: 0 !important;
}

.toast__body {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
}

.Toastify__progress-bar-theme--light {
  background: $grey2 !important;
}

.toast__progressBar--success {
  background: $green !important;
  border-radius: 0 !important;
}

.toast__progressBar--error {
  background: $red !important;
  border-radius: 0 !important;
}

.Toastify__close-button > svg {
  fill: $white !important;
}

.button__hovering:hover {
  background: $white;
  color: $black;
}

// .button__hovering:focus {
//   background: $grey2;
//   border: transparent;
//   color: $white;
// }

.button__outOfStock {
  background: $red;

  &:hover {
    background: $red2;
  }
}
