.label {
  padding: 50px 0;
}

.label::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  max-width: -webkit-fill-available;
  width: 100%;
  height: 650px;
  background: linear-gradient(
      180deg,
      #251f1e 0%,
      #fb4839 30%,
      #f5cd22 60%,
      #35afa3 90%,
      #251f1e 100%
    ),
    url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100%25" height="100%25"%3E%3Cfilter id="noiseFilter"%3E%3CfeTurbulence type="fractalNoise" baseFrequency="0.5" numOctaves="3" stitchTiles="stitch"%3E%3C/feTurbulence%3E%3CfeColorMatrix type="saturate" values="0" /%3E%3C/filter%3E%3Crect width="100%25" height="100%25" filter="url(%23noiseFilter)" opacity="0.45" /%3E%3C/svg%3E');
  background-blend-mode: hard-light;
  background-size: cover;
}

.label__image--wrapper {
  max-width: 390px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
}

.label__image {
  object-fit: cover;
  max-width: 390px;
  width: 100%;
  height: 390px;
  transition: transform 0.35s ease;
}

.label__item:hover .label__image {
  transform: scale(1.12);
}

.label__list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
}

.label__name {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 390px;
  width: 100%;
  height: 62px;
  padding: 10px;
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.07px;
  text-align: center;
  color: #ffffff;
}

.label__link:nth-child(3n + 1) .label__name {
  background: #35afa3;
}

.label__link:nth-child(3n + 2) .label__name {
  background: #fb4839;
}

.label__link:nth-child(3n) .label__name {
  background: #f5cd22;
}

.label__text {
  max-width: 780px;
  margin: 40px auto 0;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
}

@media screen and (max-width: 1000px) {
  .label {
    padding: 70px 0 20px;
  }

  .label__list {
    flex-direction: column;
  }

  .label__image {
    height: 200px;
  }
}
