.release {
  padding: 70px 0 20px;
}

.release::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  max-width: -webkit-fill-available;
  width: 100%;
  height: 650px;
  background: linear-gradient(
      180deg,
      #251f1e 0%,
      #fb4839 30%,
      #f5cd22 60%,
      #35afa3 90%,
      #251f1e 100%
    ),
    url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100%25" height="100%25"%3E%3Cfilter id="noiseFilter"%3E%3CfeTurbulence type="fractalNoise" baseFrequency="0.5" numOctaves="3" stitchTiles="stitch"%3E%3C/feTurbulence%3E%3CfeColorMatrix type="saturate" values="0" /%3E%3C/filter%3E%3Crect width="100%25" height="100%25" filter="url(%23noiseFilter)" opacity="0.45" /%3E%3C/svg%3E');
  background-blend-mode: hard-light;
  background-size: cover;
}

.release__title {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.07px;
  color: $white;
}

.release__availability {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.95px;
  color: $green;
}

.release__subtitle {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.95px;
  color: $white;
}

.release__span {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
}

.release__wrapper {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  max-width: 478px;
  width: 100%;
  height: fit-content;
  padding: 25px;
  gap: 20px;
  background: #251f1eb2;
}

.release__button {
  max-width: 428px;
  width: 100%;
  padding: 10px 0;
  border: transparent;
  background-color: $white;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $black;
}

.release__price {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.07px;
  color: $white;
}

.release__price--sale {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
  margin-left: 10px;
}

.release__container {
  position: relative;
  display: flex;
  gap: 60px;
  margin: 40px 0;
}

.swiper-pagination-bullet {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid $white !important;
  background: transparent !important;
  border-radius: 0 !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  width: 20px !important;
  height: 20px !important;
  background: $white !important;
  border-radius: 0 !important;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -6px !important;
}

.release__image {
  max-width: 700px;
  width: 100%;
}

.swiper {
  overflow: visible !important;
  width: 100%;
}

.release__iframe {
  height: 152px;
}

.release__tracklist {
  font-family: "Onest", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.4px;
  text-align: center;
  color: $grey2;
}

.release__sizesColors {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
  margin-right: 8px;
}

.release__sizes--wrapper {
  display: flex;
  align-items: center;
}

.release__sizes--thumb {
  display: none;
  align-items: center;
  gap: 20px;
  height: 43px;
  border-bottom: 2px solid $grey2;
}

.release__size--active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 43px;
  font-family: "Onest", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20.4px;
  color: $white;
  border-left: 2px solid $white;
  border-bottom: 2px solid $white;
}

.release__color--active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 43px;
  border-left: 2px solid $white;
  border-bottom: 2px solid $white;
}

.release__color--box {
  width: 20px;
  height: 20px;
  border: 1px solid $white;
}

.release__size--active:hover ~ .release__sizes--thumb {
  display: flex;
}

.release__sizes--thumb:hover {
  display: flex;
}

.release__color--active:hover ~ .release__sizes--thumb {
  display: flex;
}

.release__sizes--thumb:hover {
  display: flex;
}

.release__size {
  font-family: "Onest", sans-serif;
  font-weight: 400;
  color: $grey2;
  font-size: 16px;
  line-height: 20.4px;
  cursor: pointer;

  &:first-of-type {
    margin-left: 10px;
  }
}

.release__size:hover {
  color: $white;
}

.release__swiper {
  width: 700px;
}

@keyframes rotating {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.release__image--animation {
  animation: rotating 12s linear infinite;
}

.release__recommendation {
  font-family: "Onest", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 35.7px;
  text-align: center;
  color: $white;
  margin: 0 auto 40px;
}

@media screen and (max-width: 1000px) {
  .release__container {
    flex-direction: column;
    margin-top: 20px;
    gap: 40px;
  }

  .swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
    border: 1px solid $white !important;
  }

  .swiper-pagination-bullet-active {
    width: 15px !important;
    height: 15px !important;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    display: flex;
    justify-content: center;
    bottom: -25px !important;
  }

  .release__availability {
    text-align: center;
  }

  .release__subtitle {
    text-align: center;
  }

  .release__price {
    text-align: center;
  }

  .release__iframe {
    height: 356px;
  }

  .release__wrapper {
    gap: 15px;
  }

  .release__title {
    text-align: center;
  }

  .release__swiper {
    width: -webkit-fill-available;
  }

  .release__swiper--next {
    display: none !important;
  }

  .release__swiper--prev {
    display: none !important;
  }
}
