.admin__search {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.admin__search--text {
  color: $grey4;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  text-align: center;
}

.admin__search--label {
  color: $grey2;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  text-align: center;
}

.admin__search--button {
  max-width: 197px;
  width: 100%;
  padding: 10px 0;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  text-align: center;
  color: $grey2;
  background: $grey4;
  border: transparent;
}

.admin__search--wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.admin__search--input {
  max-width: 120px;
  width: 100%;
  padding: 5px 0 5px 5px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
  border: 1px solid $grey4;
  background: transparent;
}

.admin__search--input::placeholder {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey4;
}

.orders__line {
  width: 100%;
  height: 1px;
  background: $grey4;
  margin-top: 10px;
}

.orders__wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  padding-right: 20px;
}

.orders__sort--thumb {
  display: flex;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey4;
}

.orders__data {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  padding-right: 20px;
}

.orders__data--text {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
}

.orders__data--status {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $green;
}

.orders__data--dots {
  position: absolute;
  right: 0;
  padding: 8px;
  cursor: pointer;
}

.orders__button {
  padding: 10px 20px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;

  &.green {
    background: $green;
    border: transparent;
  }

  &.yellow {
    background: $yellow;
    border: transparent;
  }

  &.red {
    background: $red;
    border: transparent;
  }
}

.orders__button--wrapper {
  position: absolute;
  right: -355px;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.order__top--info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.order__back--button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border: transparent;
  background: $green;
}

.order__title {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.08px;
  color: $white;
}

.order__top--wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.order__info--text {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
}

.order__block--title {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.95px;
  color: $white;
}

.order__info--section {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 10px;
}

.order__info--item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order__block--wrapper {
  display: flex;
  gap: 10px;
}

.order__block--subtitle {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
  width: 60px;
}

.order__block--value {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
}

.order__product--amount {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
}

.order__product--section {
  margin-top: 30px;
}

.order__product--wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  margin-top: 10px;
}

.order__product--subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey4;
}

.order__product--text {
  font-family: "Onest", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.4px;
  color: $white;
}

.orders__data--wrapper {
  position: relative;
}

.orders__modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 160px;
  width: 100%;
  padding: 10px 20px;
  background: $black;
  border: 1px solid $grey4;
}

.orders__modal--title {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
}

.orders__modal--button {
  padding: 0 10px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  border: transparent;
  background: transparent;
}

.orders__modal--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
