.processing__backdrop {
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: #151312b2;
  backdrop-filter: blur(15px);

  opacity: 1;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.processing__modal {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 200px 95px;
  max-width: 764px;
  width: 100%;

  background-color: $black;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(-50%, -50%);
}

.processing__title {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.08px;
  color: $white;
  text-align: center;
  margin-bottom: 45px;
}

.processing__button {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 50px;
  margin: 0 auto;
  border: 1px solid $white;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
  background: transparent;

  &.small {
    margin: 0;
  }
}

.processing__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 45px;
}

.processing__status--text {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.08px;
  text-align: center;
  &.green {
    color: $green;
  }

  &.red {
    color: $red;
  }

  &.white {
    color: $white;
  }
}

.processing__buttons--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 45px;
}

@media screen and (max-width: 1000px) {
  .processing__modal {
    padding: 50px 25px;
  }

  .processing__title {
    font-size: 28px;
    line-height: 35.7px;
  }

  .processing__button {
    font-size: 16px;
    line-height: 20.4px;
  }

  .processing__status--text {
    font-size: 28px;
    line-height: 35.7px;
  }

  .processing__icon {
    margin: 0 auto 15px;
  }

  .processing__buttons--wrapper {
    gap: 10px;
    margin-top: 15px;
  }

  .processing__button {
    padding: 10px 20px;
  }

  .processing__icon {
    width: 100px;
    height: 100px;
  }
}
