.blog {
  padding: 50px 0;
  background: linear-gradient(
      180deg,
      #251f1e 0%,
      #fb4839 12%,
      #f5cd22 24%,
      #35afa3 36%,
      #251f1e 48%,
      #251f1e 100%
    ),
    url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100%25" height="100%25"%3E%3Cfilter id="noiseFilter"%3E%3CfeTurbulence type="fractalNoise" baseFrequency="0.5" numOctaves="3" stitchTiles="stitch"%3E%3C/feTurbulence%3E%3CfeColorMatrix type="saturate" values="0" /%3E%3C/filter%3E%3Crect width="100%25" height="100%25" filter="url(%23noiseFilter)" opacity="0.45" /%3E%3C/svg%3E');
  background-blend-mode: hard-light;
  background-size: cover;
}
