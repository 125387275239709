.performer {
  padding: 70px 0 20px;
}

.performer::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  max-width: -webkit-fill-available;
  width: 100%;
  height: 650px;
  background: linear-gradient(
      180deg,
      #251f1e 0%,
      #fb4839 30%,
      #f5cd22 60%,
      #35afa3 90%,
      #251f1e 100%
    ),
    url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100%25" height="100%25"%3E%3Cfilter id="noiseFilter"%3E%3CfeTurbulence type="fractalNoise" baseFrequency="0.5" numOctaves="3" stitchTiles="stitch"%3E%3C/feTurbulence%3E%3CfeColorMatrix type="saturate" values="0" /%3E%3C/filter%3E%3Crect width="100%25" height="100%25" filter="url(%23noiseFilter)" opacity="0.45" /%3E%3C/svg%3E');
  background-blend-mode: hard-light;
  background-size: cover;
}

.performer__image {
  max-width: 1240px;
  width: 100%;
  margin-top: 40px;
}

.performer__title {
  margin-top: 40px;
  font-family: "Onest", sans-serif;
  font-size: 36px;
  font-weight: 800;
  line-height: 45.9px;
  text-align: center;
  color: $white;
  text-shadow: 0px 4px 4px #00000040;
}

.performer__social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
}

.performer__social--svg {
  fill: $grey;
}

.performer__social--svg:hover {
  fill: $white;
}

.performer__description {
  display: flex;
  justify-content: center;
  max-width: 780px;
  width: 100%;
  margin: 20px auto 0;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
}

.performer__albums {
  display: flex;
  margin: 40px auto;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  width: 100%;
}

.performer__album {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.performer__album--title {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.07px;
  text-align: left;
  color: $white;
}

.performer__album--thumb {
  max-width: 800px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 200px;
}

.performer__album--list {
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 100%;
}

.performer__album--item {
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 25%;
  height: 100%;
  max-height: 100%;
}

.performer__album--social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  text-align: left;
  color: $white;
}

.performer__album--wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.performer__album--item:nth-child(3n + 1) {
  background: $green;
}

.performer__album--item:nth-child(3n + 2) {
  background: $red;
}

.performer__album--item:nth-child(3n + 3) {
  background: $yellow;
}

.performer__album--play {
  fill: $white;
}

.performer__album--item:nth-child(3n + 1):hover .performer__album--play {
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  fill: $green;
  stroke: $white;
}

.performer__album--item:nth-child(3n + 2):hover .performer__album--play {
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  fill: $red;
  stroke: $white;
}

.performer__album--item:nth-child(3n + 3):hover .performer__album--play {
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  fill: $yellow;
  stroke: $white;
}

.performer__album--image {
  max-width: 200px;
  width: 100%;
}

.recommendations__author {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  text-align: center;
  color: $white;
}

.recommendations__list {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 40px;
  margin-bottom: 50px;
}

.recommendations__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.recommendations__name {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  text-align: center;
  color: $white;
}

.recommendations__price {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.07px;
  color: $white;
}

.recommendations__button {
  max-width: 262px;
  width: 100%;
  padding: 12px 0;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  border: transparent;
}

.recommendations__button--hover:hover {
  background-color: transparent !important;
  color: $white !important;
  border: 1px solid $white;
}

.performer__bottom--text {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.07px;
  text-align: center;
  color: $white;
  text-shadow: 0px 4px 4px #00000040;
}

.recommendations__image {
  max-width: 390px;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .performer__album--image {
    max-width: 160px;
  }

  .recommendations__list {
    flex-wrap: wrap;
  }

  .performer__album--thumb {
    height: 160px;
  }

  .performer__album--list {
    height: 160px;
  }

  .performer__album--social {
    padding: 0 10px;
    font-family: "Onest", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.4px;
    text-align: left;
  }
}

@media screen and (max-width: 370px) {
  .recommendations__name {
    font-size: 16px;
  }
}

@media screen and (max-width: 360px) {
  .performer__album--social {
    padding: 0 8px;
    font-size: 14px;
  }

  .performer__album--svg {
    width: 28px;
    height: 28px;
  }

  .performer__album--wrapper {
    gap: 6px;
  }
}

@media screen and (max-width: 350px) {
  .performer__album--social {
    padding: 0 5px;
    font-size: 12px;
  }

  .performer__album--svg {
    width: 26px;
    height: 26px;
  }

  .performer__album--wrapper {
    gap: 4px;
  }

  .recommendations__name {
    font-size: 15px;
  }
}
