.title {
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: space-between;
}

.title__language {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 45px;
  height: 43px;
  padding: 10px;
  border: 1px solid $white;
  font-family: "Onest", sans-serif;
  text-transform: uppercase;
  color: $white;
  cursor: pointer;
  animation: appearing 1s forwards;

  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  transition: 0.3s all;
}

.title__language:hover {
  background: $white;
  color: $black;
}

@keyframes disappearing {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.title__text--hide {
  display: none;
  margin-right: auto;
  font-family: "Onest", sans-serif;
  font-size: 36px;
  font-weight: 800;
  line-height: 45.9px;
  text-shadow: 0px 4px 4px #00000040;
  color: $white;
  animation: disappearing 0.33s forwards;
}

@keyframes growing {
  0% {
    width: 25%;
  }

  100% {
    width: 100%;
  }
}

@keyframes lessing {
  0% {
    width: 100%;
  }

  100% {
    width: 30%;
  }
}

.out {
  animation: lessing 0.6s forwards;
}

.title__input {
  border-width: 0px 0px 1px 1px;
  height: 43px;
  padding-left: 16px;
  border-style: solid;
  background: transparent;
  border-color: $white;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: #ffffff00;
  caret-color: $white;

  &::placeholder {
    font-family: "Onest", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.95px;
    color: $white;
  }

  &:focus {
    outline: none;
    animation: growing 0.6s forwards;
  }
}

.title__input--active {
  border-width: 0px 1px 1px 1px;
}

.title__input--text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey3;
}

.title__input--textActive {
  color: $white;
}

@keyframes appearing {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes appearingMainTitle {
  0% {
    display: none;
    opacity: 0;
  }

  23% {
    opacity: 0;
    height: 51px;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.title__text {
  font-family: "Onest", sans-serif;
  font-size: 36px;
  font-weight: 800;
  line-height: 45.9px;
  text-shadow: 0px 4px 4px #00000040;
  color: $white;
  animation: appearing 1s forwards;
}

.title__text--appearing {
  animation: appearingMainTitle 1s forwards;
}

.title__wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.width100 {
  display: flex;
  justify-content: flex-end;
  width: 0;
}

.width100:focus-within {
  animation: growing 0.6s forwards !important;
}

.title__searching {
  justify-content: flex-end;
}

@media screen and (min-width: 1000px) {
  .title__types--wrapper {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .title {
    justify-content: center;
  }

  .title__wrapper {
    display: none;
  }

  .title__typing {
    align-items: center;
    justify-content: space-between;
  }

  .title__text {
    font-family: "Onest", sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 35.7px;
  }

  .title__types--wrapper {
    display: flex;
  }

  .title__type {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    transition: background-color 0.35s ease-in-out;
  }
}
