.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 50px 0;
  background-color: $black2;
}

.footer__thumb {
  display: flex;
  justify-content: space-between;
  max-width: 937px;
  width: 100%;
  gap: 70px;
}

.footer__title {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
}

.footer__text {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Onest", sans-serif;
  color: $grey;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.4px;

  &:hover {
    color: $white;
  }

  &:hover .footer__svg {
    fill: $white;
  }
}

.footer__social--text {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Onest", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.4px;
  color: $grey;

  &:hover {
    color: $white;
  }
}

.footer__thumb--left {
  max-width: 383px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer__thumb--right {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 141px;
  width: 100%;
  gap: 15px;
}

.footer__info--wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer__location {
  gap: 5px;
}

.footer__svg {
  fill: $grey;
}

.footer__link {
  width: fit-content;
}

@media screen and (max-width: 1000px) {
  .footer {
    padding: 20px;
  }

  .footer__thumb {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .footer__info--wrapper {
    flex-direction: row;
  }

  .footer__social--wrapper {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .footer__text:not(:first-of-type) {
    padding-left: 15px;
    border-left: 1.5px solid $grey2;
  }

  .footer__social {
    display: none;
  }

  .footer__wrapper {
    gap: 5px;
  }

  .footer__thumb--right {
    flex-direction: column;
  }

  .footer__location {
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 10px;
  }

  .footer__thumb--right {
    gap: 20px;
  }

  .footer__svg {
    fill: $grey2;
  }

  .footer__text {
    color: $grey2;
  }

  .footer__title {
    font-family: "Onest", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.4px;
  }
}

.footer__location--text:hover {
  color: $grey;
}

// @media screen and (min-width: 1680px) {
//   .footer {
//     position: fixed;
//     bottom: 0;
//   }
// }

@media screen and (min-width: 1000px) {
  .footer {
    position: absolute;
    bottom: 0;
  }
}
