.about {
  padding: 20px 0 45px;
}

.about__title {
  margin-bottom: 10px;
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.07px;
  text-align: center;
  color: $white;
}

.about__text {
  max-width: 780px;
  width: 100%;
  margin: 0 auto 40px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
}

.about__wrapper {
  display: flex;
  gap: 40px;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.about__image {
  max-width: 478px;
  width: 100%;
}

.about__thumb {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.about__info {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.07px;
  text-align: left;
  text-transform: uppercase;
  color: $white;
}

.about__description {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  text-align: left;
  color: $grey2;
}

.about__button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 261px;
  width: 100%;
  height: 40px;
  font-family: "Onest", sans-serif;
  font-size: 14.46px;
  font-weight: 400;
  line-height: 18.44px;
  color: $black;
  background-color: $white;
  border: transparent;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .about {
    padding: 20px 0 5px;
  }

  .about__wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .about__info {
    max-width: 350px;
    width: 100%;
    text-align: center;
  }

  .about__description {
    text-align: center;
  }

  .about__button {
    max-width: 198px;
    height: 43px;
    margin: 0 auto;
    font-weight: 600;
    cursor: pointer;
  }
}
