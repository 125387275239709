.pagination__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 60px;
}

.pagination__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 35px;
  height: 35px;
  background: transparent;
  border: transparent;
}

.pagination__button--disabled svg {
  background: $grey5;
}

.pagination__button--disabled path {
  stroke: $black2;
}

.pagination__number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background: transparent;
  color: $white;
  border: 1px solid $white;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
}

.pagination__number--active {
  background: $white;
  color: $black2;
}
