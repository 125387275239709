.admin__header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  width: 100%;
  background: linear-gradient(
    90deg,
    #251f1e 0%,
    #fb4839 25%,
    #f5cd22 50%,
    #35afa3 75%,
    #251f1e 100%
  );
}

.admin__header--title {
  font-family: "Onest", sans-serif;
  font-size: 36px;
  font-weight: 800;
  line-height: 45.9px;
  color: $white;
}

.admin__tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.admin__tab--link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 370px;
  padding: 10px 0;
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.08px;

  border: transparent;
}

.admin__tab--link:first-of-type {
  background: $green;
  color: $white;
}

.admin__tab--link:nth-child(2) {
  background: $red;
  color: $white;
}

.admin__tab--link:nth-child(3) {
  background: $yellow;
  color: $white;
}

.admin__tab--link.active {
  color: $black2;
  background: $white;
}

.admin__container {
  width: 760px;
  margin: 0 auto;
  padding: 40px 0;
}

.admin__action {
  width: 100%;
  padding: 10px 0;
}

.admin__action {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  width: 100%;
}

.admin__action:first-of-type {
  border-bottom: 1px solid $grey4;
}

.admin__action--item {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.admin__action--wrapper {
  display: flex;
  gap: 25px;
}

.admin__action--text {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey4;
  cursor: pointer;
}

.admin__action--textActive {
  color: $grey2;
}

.admin__action--svg1 {
  stroke: $grey4;
}

.admin__action--svg {
  fill: $grey4;
}

.admin__action--item:hover .admin__action--svg1 {
  stroke: $grey2;
}

.admin__action--item:hover .admin__action--svg {
  fill: $grey2;
}

.admin__action--item:hover .admin__action--text {
  color: $grey2;
}

.admin__action--item.admin__action--itemActive .admin__action--svg {
  fill: $grey2;
}
.admin__action--item.admin__action--itemActive .admin__action--text {
  color: $grey2;
}

.admin__action--languages {
  display: flex;
  gap: 25px;
}

.admin__action--text:hover {
  color: $grey2;
}

.admin__back--link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background: $green;
}

.admin__addGoods--wrapper {
  display: flex;
  gap: 20px;
}

.admin__addGoods--title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: $green;
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.08px;
  color: $white;
}

.admin__addArtist--title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: $yellow;
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.08px;
  color: $white;
}

.admin__addGoods--inputWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.admin__addArtist--inputWrapper {
  width: 100%;
}

.admin__addGoods--label {
  margin: 10px 0;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  text-align: left;
  color: $grey2;
}

.admin__addGoods--input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  background: $black2;
  border: transparent;
  padding-left: 20px;
  cursor: pointer;
  color: $white;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
}

.admin__addGoods--input::placeholder {
  color: $grey4;
}

.admin__addGoods--radio {
  gap: 10px;
  padding-left: 10px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey4;
}

.admin__addGoods--radioSquare {
  width: 24px;
  height: 24px;
  border: 1px solid $grey4;
}

.admin__addGoods--radioChoosen {
  border: transparent;
  background: $white;
}

.admin__addGoods--spaceT {
  margin-top: 10px;
}

.admin__addGoods--priceWrapper {
  position: relative;
  width: 100%;
}

.admin__addGoods--currency {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
}

.admin__addGoods--or {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey4;
}

.admin__addGoods--submit {
  width: 158px;
  padding: 10px 20px;
  margin-top: 10px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
  background: $green;
  border: transparent;
  cursor: pointer;
}

.admin__addGoods--fileWrapper {
  display: flex;
  gap: 10px;
  height: 160px;
}

.admin__addGoods--fileThumb {
  overflow: hidden;
  position: relative;
  background: $black2;
  width: 160px;
}

.admin__addGoods--fileThumb.dashedBorder:before {
  content: "";
  position: absolute;
  border: 10px dashed $grey2;
  top: -8px;
  bottom: -8px;
  left: -8px;
  right: -8px;
}

.admin__addArtist--fileThumb.dashedBorder:before {
  content: "";
  position: absolute;
  border: 10px dashed $grey2;
  top: -8px;
  bottom: -8px;
  left: -8px;
  right: -8px;
}

.admin__addGoods--fileThumb.dashedBorderRed:before {
  content: "";
  position: absolute;
  border: 10px dashed $red;
  top: -8px;
  bottom: -8px;
  left: -8px;
  right: -8px;
}

.admin__addArtist--fileThumb.dashedBorderRed:before {
  content: "";
  position: absolute;
  border: 10px dashed $red;
  top: -8px;
  bottom: -8px;
  left: -8px;
  right: -8px;
}

.admin__addGoods--inputFile {
  width: 160px;
  height: 160px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  position: absolute;
}

.admin__image--bin {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.admin__addGoods--inputFile:hover ~ .admin__image--bin {
  opacity: 1;
  pointer-events: all;
}

.admin__image--bin:hover {
  opacity: 1;
  pointer-events: all;
}

.admin__addGoods--bin {
  width: 160px;
  height: 160px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  position: absolute;
}

.admin__addGoods--coverImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.admin__addGoods--img {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.admin__addArtist--artistfileWrapper {
  display: flex;
  height: 288px;
}

.admin__addArtist--fileThumb {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: $black2;
}

.admin__addArtist--info {
  margin-top: 10px;
  font-family: "Onest", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.4px;
  color: $grey4;
}

.admin__addArtist--input {
  height: 52px;
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.08px;
}

.admin__addArtist--input::placeholder {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.08px;
}

.admin__addArtist--inputFile {
  position: absolute;
  z-index: 2;
  width: 200px;
  height: 200px;
  cursor: pointer;
  opacity: 0;
}

.admin__addArtist--fileWrapper {
  display: flex;
  gap: 10px;
  height: 200px;
}

// .admin__addArtist--coverImg {
// }

.admin__addArtist--coverImgWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.admin__addArtist--coverImg {
  width: 32px;
  height: 32px;
}

.admin__addArtist--coverImgText {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey4;
}

.admin__addArtist--coverImgThumb {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.admin__addArtist--coverImgDescription {
  font-family: "Onest", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.4px;
  color: $grey4;
  text-align: center;
}

.admin__addArtist--wrapperDescription {
  width: 148px;
}

.admin__addArtist--submit {
  width: 218px;
  padding: 10px 20px;
  margin-top: 10px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
  background: $green;
  border: transparent;
  cursor: pointer;
}

.admin__addArtist--addAlbum {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 20px 0;
  gap: 10px;
  margin-top: 10px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey4;
  border: transparent;
  background: $grey2;
  cursor: pointer;
}

.admin__addGoods--smallLabel {
  margin: 10px 0;
  font-family: "Onest", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.4px;
  color: $grey4;
}

.admin__addGoods--merchCoverImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  width: 32px;
  height: 32px;
}

.admin__addGoods--merchSquare {
  position: absolute;
  left: 10px;
  bottom: 10px;
  pointer-events: none;
}

.admin__addGoods--colorBtn {
  margin-top: 10px;
  width: 100%;
  height: 43px;
  background: $grey2;
  border: transparent;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey4;
}

.admin__addGoods--colorBtnSvg {
  fill: $grey4;
}

.admin__addArtist--artistImg {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 288px;
}

.admin__addArtist--artistImage {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.admin__artist--list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 760px;
}

.admin__artist--item {
  display: flex;
}

.admin__artist--image {
  width: 128px;
  height: 128px;
}

.admin__artist--artistName {
  padding-left: 10px;
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.08px;
  color: $white;
}

.admin__artist--artistId {
  margin-left: 10px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 20.08px;
  color: $white;
}

.admin__artist--artistNameWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background: $black2;
}

.admin__artist--adapted {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $red;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
}

.admin__artist--action {
  position: absolute;
  bottom: 0.2px;
  right: -0.4px;
  display: flex;
  flex-direction: column;
  height: 128px;
}

.admin__addArtist--inputAlbumWrapper {
  position: relative;
}

.admin__addArtist--bin {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.admin__artist--labelList {
  cursor: pointer;
}

.admin__artist--artistNameWrapperArchived {
  background: $grey4;
}

.admin__addGoods--smallLabelWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin__goodsList--artistNameWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  padding-top: 10px;
  padding-left: 10px;
}

.admin__goodsList--artistName {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
  height: 50%;
}

.admin__goodsList--albumName {
  font-family: "Onest", sans-serif;
  font-weight: 400;
  color: $white;
  font-size: 16px;
  line-height: 20.4px;
}

.admin__goodsList--additional {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-top: 10px;
}

.admin__goodsList--id {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
  height: 50%;
}

.admin__goodsList--amount {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
}

.showcase__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.showcase__item {
  width: 100%;
}

.showcase__index {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey4;
}

.showcase__top--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  height: 43px;
  background: $black2;
}

.showcase__top--thumb {
  display: flex;
  gap: 5px;
}

.showcase__arrow--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.showcase__top--text {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey4;
}

.showcase__top--delete {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $red;
}

.showcase__image--wrapper {
  display: flex;
}

.admin__addGoods--inputError {
  border: 1px solid $red;
}
