.sort__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.sort__modal2 {
  position: absolute;
  transform: translateY(100%);
  right: 0;
  bottom: -1px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 140px;
  height: 130px;
  padding: 10px;
  background: $black;
  z-index: 2;
}

@keyframes whiteOut {
  0% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}

@keyframes whiteOut2 {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.sort__button--stock {
  position: relative;
  padding: 10px;
  border: transparent;
  background-color: transparent;
  cursor: pointer;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
}

.sort__button--stock2 {
  position: relative;
  padding: 10px;
  border: transparent;
  background-color: transparent;
  cursor: pointer;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
}

.sort__button--stock.active {
  color: $grey3;
}

.sort__button--stock2.active {
  color: $grey3;
}

.sort__button--stock::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: $white;
}

.sort__button--stock2::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: $white;
}

.sort__button--stock.active::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: $white;
  animation: whiteOut 0.7s forwards;
}

.sort__button--stock2.active::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: $white;
  animation: whiteOut 0.7s forwards;
}

.sort__sorting--button {
  min-width: 70px;
  padding: 10px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey3;
  background-color: transparent;
  border: transparent;
}

.sort__sorting--type {
  padding: 10px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
  background-color: transparent;
  border: transparent;
}

.sort__sorting2--wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 10px 15px;
  border: 1px solid $white;
}

.sort__sorting2--text {
  font-family: "Onest", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.4px;
  color: $white;
}

.sort__sorting--wrapper {
  position: relative;
}

.sort__modal {
  position: absolute;
  transform: translateY(100%);
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 181px;
  height: 142px;
  padding: 10px;
  background: $black;
  z-index: 3;
}

.sort__select--text {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey3;
}

.sort__select--wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.sort__sorting--buttonActive {
  border-left: 1px solid $white;
  border-bottom: 1px solid $white;
}

.sort__sorting--typeActive {
  border-bottom: 1px solid $white;
}

@media screen and (min-width: 1000px) {
  .sort__sorting2--wrapper {
    display: none;
  }

  .sort__modal2 {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .sort__modal {
    display: none;
  }

  .sort__select--text {
    font-family: "Onest", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.4px;
    color: $grey2;
  }

  .sort__button--stock {
    font-family: "Onest", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.4px;
  }

  .sort__button--stock2 {
    font-family: "Onest", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.4px;
  }

  .sort__button--stock::after {
    height: 3px;
  }

  .sort__button--stock.active::after {
    height: 3px;
  }

  .sort__button--stock2::after {
    height: 3px;
  }

  .sort__button--stock2.active::after {
    height: 3px;
  }

  .sort__sorting--wrapper {
    display: none;
  }

  .sort__wrapper {
    margin-top: 20px;
  }
}

@media screen and (max-width: 335px) {
  .sort__button--stock {
    font-size: 14px;
  }

  .sort__button--stock2 {
    font-size: 14px;
  }
}
