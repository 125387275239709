.header {
  background-color: $black2;
  position: fixed;
  left: 0;
  display: none;
  flex-direction: column;
  height: 100%;
  box-shadow: 4px 0px 10px 0px #00000026;
  z-index: 5;
  width: 80px;
  transition: width 0.2s ease-out;
}

.header:hover {
  width: 258px;
}

.header .header__link--title {
  display: block;
  width: 0px;
  opacity: 0;
  display: none;
}

@keyframes titleAppearing {
  0% {
    opacity: 0;
    width: 0px;
  }
  95% {
    width: 0px;
  }
  100% {
    width: fit-content;
    opacity: 1;
  }
}

@keyframes titleDisappearing {
  0% {
    opacity: 1;
    width: fit-content;
  }
  100% {
    opacity: 0;
    width: 0px;
  }
}

.header:hover .header__link--title {
  display: block;
  width: fit-content;
  opacity: 1;

  animation: titleAppearing 0.6s ease-in forwards;
}

.header:not(:hover) .header__link--title {
  animation: titleDisappearing 0.05s ease-in forwards;
}

// .header {
//   background-color: $black2;
//   position: fixed;
//   left: 0;
//   display: none;
//   flex-direction: column;
//   height: 100%;
//   box-shadow: 4px 0px 10px 0px #00000026;
//   z-index: 5;
//   width: 80px;
//   transition: width 0.2s ease-out;
// }

// @keyframes headerGrowing {
//   0% {
//     width: 80px;
//   }

//   100% {
//     width: 258px;
//   }
// }

// @keyframes titleAppearing {
//   0% {
//     opacity: 0;
//     width: 0px;
//   }

//   95% {
//     width: 0px;
//   }
//   100% {
//     width: fit-content;
//     opacity: 1;
//   }
// }

// .header:hover {
//   // animation: headerGrowing 0.2s ease-out forwards;
//   width: 258px;
// }

// .header:hover .header__link--title {
//   display: block;
//   width: 0px;
//   animation: titleAppearing 0.5s ease-in forwards !important;
// }

.header__icon--logo {
  width: 80px;
  height: 80px;
}

.header__icon--activeLogo {
  width: 80px;
  height: 80px;
}

.header__link {
  display: inline-block;
  // height: 80px;
  // width: 80px;
  stroke: $white;

  &:first-of-type {
    margin-top: 30px;
    margin-bottom: auto;
  }

  &:last-of-type {
    margin-top: auto;
  }

  &--label {
    margin-top: 30px;
  }

  &--label:hover .header__link--title {
    color: $green;
  }

  &--vinyl:hover .header__link--title {
    color: $red;
  }

  &--cassette:hover .header__link--title {
    color: $yellow;
  }

  &--merch:hover .header__link--title {
    color: $green;
  }

  &--cart:hover .header__link--title {
    color: $yellow;
  }

  &--label.active:hover .header__link--title {
    color: $white;
  }

  &--vinyl.active:hover .header__link--title {
    color: $white;
  }

  &--cassette.active:hover .header__link--title {
    color: $white;
  }

  &--merch.active:hover .header__link--title {
    color: $white;
  }

  &--cart.active:hover .header__link--title {
    color: $white;
  }

  &--blog {
    margin-bottom: 30px;
  }

  &:hover.active .header__icon--label {
    stroke: $white;
  }

  &:hover .header__icon--label {
    stroke: $green;
  }

  &:hover .header__icon--vinyl {
    stroke: $red;
  }

  &:hover.active .header__icon--vinyl {
    stroke: $white;
  }

  &:hover .header__icon--cassette {
    stroke: $yellow;
  }

  &:hover.active .header__icon--cassette {
    stroke: $white;
  }

  &:hover .header__icon--merch {
    stroke: $green;
  }

  &:hover.active .header__icon--merch {
    stroke: $white;
  }

  &:hover .header__icon--blog {
    stroke: $red;
  }

  &:hover.active .header__icon--blog {
    stroke: $white;
  }

  &:hover .header__icon--cart {
    stroke: $yellow;
  }

  &:hover.active .header__icon--cart {
    stroke: $white;
  }

  &.active.header__link--label {
    background-color: $green;
  }

  &.active.header__link--vinyl {
    background-color: $red;
  }

  &.active.header__link--cassette {
    background-color: $yellow;
  }

  &.active.header__link--merch {
    background-color: $green;
  }

  &.active.header__link--blog {
    background-color: $red;
  }

  &.active.header__link--cart {
    background-color: $yellow;
  }

  &:hover.active {
    stroke: $white;
  }

  & .header__icon--activeLogo {
    display: none;
  }

  &.active .header__icon--logo {
    display: none;
  }

  &.active .header__icon--activeLogo {
    display: block;
  }
}

.header__icon {
  &--label {
    stroke: inherit;
    height: 80px;
    width: 80px;
  }

  &--vinyl {
    stroke: inherit;
    height: 80px;
    width: 80px;
  }

  &--cassette {
    stroke: inherit;
    height: 80px;
    width: 80px;
  }

  &--merch {
    stroke: inherit;
    height: 80px;
    width: 80px;
  }

  &--blog {
    stroke: inherit;
    height: 80px;
    width: 80px;
  }

  &--cart {
    stroke: inherit;
    height: 80px;
    width: 80px;
  }
}

.header__link--wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.header__link--title {
  display: none;
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.08px;
  color: $white;
}

.header__mobile {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: $black;
  z-index: 5;
}

.header__mobile--wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header__mobile--title {
  font-family: "Onest", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 35.7px;
  color: $white;
}

.header__mobile--modal {
  opacity: 1;
  transition: 0.5s opacity;
  width: 100%;
}

.header__modal--hidden {
  opacity: 0;
  pointer-events: none;
  transition: 0.5s opacity;
}

.header__mobile--thumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 30px;
  height: 50px;
  width: 100%;
  gap: 10px;
}

.header__search--wrapper {
  position: relative;
}

@keyframes appearing {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.header__search--icon {
  display: none;
  // position: absolute;
  // left: 10px;
  // top: 45%;
  // transform: translateY(-50%);
  // opacity: 0;
  // animation: appearing 0.3s ease-in-out 0.5s forwards;
}

@keyframes growingInput {
  0% {
    width: 20%;
  }

  100% {
    width: 100%;
  }
}

.header__mobile--input {
  width: 100%;
  height: 34px;
  padding-left: 10px;
  border: transparent;
  border-bottom: 1px solid $grey2;
  border-right: transparent;
  border-top: transparent;
  border-radius: 0;
  background: transparent;
  animation: growingInput 0.5s forwards;
  font-family: "Onest", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.4px;
  color: #ffffff00;
  caret-color: $white;
}

.header__input--text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-family: "Onest", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.4px;
  color: $grey3;
}

.header__input--textActive {
  color: $white;
}

.header__modal--item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 56px;

  &:nth-child(3n + 1) {
    background: $green;
  }

  &:nth-child(3n + 2) {
    background: $red;
  }

  &:nth-child(3n) {
    background: $yellow;
  }

  &:last-of-type {
    background: $white;
  }
}

.header__language--wrapper {
  display: flex;
}

.header__quantity--wrapper {
  position: relative;
}

.header__cart--quantity {
  position: absolute;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Onest", sans-serif;
  color: $white;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
}

.header__modal--title {
  font-family: "Onest", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 35.7px;
  color: $white;
}

.header__modal--titleBlack {
  color: $black;
}

.header__modal--langButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 40px;
  border: 1px solid $black;
  background: $grey2;
  font-family: "Onest", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.4px;
  color: $black;
  cursor: pointer;

  &:last-of-type {
    border-left: transparent;
  }
}

.header__logo--hovering1:hover .header__icon--logo path {
  stroke: $green;
}

.header__logo--hovering1:hover .header__link--title {
  color: $green;
}

.header__logo--hovering1:hover .header__link--title {
  color: $green;
}

.header__logo--hovering2:hover .header__icon--logo path {
  stroke: $red;
}

.header__logo--hovering2:hover .header__link--title {
  color: $red;
}

.header__logo--hovering3:hover .header__icon--logo path {
  stroke: $yellow;
}

.header__logo--hovering3:hover .header__link--title {
  color: $yellow;
}

.header__langButton--active {
  background: $white;
  transition: 0.5s background;
  cursor: pointer;
}

@media screen and (min-width: 1000px) {
  .header__mobile {
    display: none;
  }

  .header {
    display: flex;
  }
}

@media screen and (min-width: 1680px) {
  // .header__link {
  //   width: 100px;
  //   height: 100px;
  // }

  .header__icon--logo {
    width: 100px;
    height: 100px;
  }

  .header__icon--activeLogo {
    width: 100px;
    height: 100px;
  }

  .header__icon {
    &--label {
      stroke: inherit;
      height: 100px;
      width: 100px;
    }

    &--vinyl {
      stroke: inherit;
      height: 100px;
      width: 100px;
    }

    &--cassette {
      stroke: inherit;
      height: 100px;
      width: 100px;
    }

    &--merch {
      stroke: inherit;
      height: 100px;
      width: 100px;
    }

    &--blog {
      stroke: inherit;
      height: 100px;
      width: 100px;
    }

    &--cart {
      stroke: inherit;
      height: 100px;
      width: 100px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .header__icon--label {
    width: 50px;
    height: 50px;
    stroke: $white;
  }

  .header__icon--label path {
    stroke-width: 3px;
  }

  .header__icon--vinyl {
    width: 50px;
    height: 50px;
    stroke: $white;
  }

  .header__icon--vinyl path {
    stroke-width: 3px;
  }

  .header__icon--cassette {
    width: 50px;
    height: 50px;
    stroke: $white;
  }

  .header__icon--cassette path {
    stroke-width: 3px;
  }

  .header__icon--merch {
    width: 50px;
    height: 50px;
    stroke: $white;
  }

  .header__icon--merch path {
    stroke-width: 3px;
  }

  .header__mobile--backdrop {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
}
