.productsList__list {
  display: flex;
  // align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
  gap: 5px;
  row-gap: 50px;
}

.productList__item--wrapper {
  position: relative;
}

.productsList__item {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 410px;
  padding: 10px;
  width: 100%;
  height: 100%;
  gap: 10px;
  border: 1px solid transparent;
  border-bottom: 0;
  transition: background 0.3s ease, border-color 0.3s ease,
    backdrop-filter 0.3s ease, z-index 0.3s ease;
}

@media screen and (min-width: 1000px) {
  .productList__item--wrapper:hover .productsList__item {
    z-index: 2;
    background: #251f1e4d;
    border: 1px solid #ffffff4d;
    border-bottom: 0;
    backdrop-filter: blur(30px);
  }

  .productList__item--wrapper:hover .productList__button--wrapper {
    opacity: 1;
    pointer-events: all;
    background: #251f1e70;
    border: 1px solid #ffffff4d;
    border-top: 0;
    box-shadow: 0px 4px 4px 0px #00000040;
    backdrop-filter: blur(5px);
  }
}

.productsList__image {
  max-width: 390px;
  width: 100%;
}

.productsList__thumb {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.productsList__author {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.95px;
  text-align: center;
  color: $white;
}

.productsList__name {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  text-align: center;
  color: $white;
}

.productsList__price--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.productsList__price {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.07px;
  text-align: center;
  color: $white;
}

.productsList__price--sale {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
}

.productsList__button {
  display: flex;
  justify-content: center;
  // max-width: 185px;
  // width: 100%;
  height: 43px;
  padding: 10px 50px;
  margin: 0 auto;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
  background: transparent;
  border: 1px solid $white;
  transition: 0.3s all;
}

.productList__button--wrapper {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
  left: 0px;
  bottom: -53px;
  width: 100%;
  padding-bottom: 10px;
  border: 1px solid transparent;
  border-top: 0;
  transition: background 0.3s ease, border-color 0.3s ease,
    backdrop-filter 0.3s ease, z-index 0.3s ease, opacity 0.3s ease;
}

@media screen and (max-width: 1000px) {
  .productsList__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .productList__button--wrapper {
    display: none;
  }

  .productsList__list {
    margin-top: 20px;
  }

  .productsList__item {
    max-width: 170px;
    background: #251f1e4d;
    // border: 1px solid #ffffff4d;
  }

  .products__item--type1 {
    max-width: 170px;
  }

  .productsList__item--type2 {
    max-width: 330px;
    flex-direction: row;
    align-items: center;
  }

  .productsList__image {
    max-width: 170px;
  }

  .productsList__author {
    font-family: "Onest", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.4px;
  }

  .productsList__name {
    font-family: "Onest", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 17.85px;
  }

  .productsList__price {
    font-family: "Onest", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.4px;
  }

  .productsList__button {
    font-family: "Onest", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.4px;
    color: $white;
    background-color: transparent;
    border: 1px solid $white;
  }

  .productsList__list--type2 {
    gap: 10px;
  }
}

@media screen and (max-width: 389px) {
  .productsList__item--type1 {
    max-width: 150px;
  }
}

@media screen and (max-width: 349px) {
  .productsList__item--type1 {
    max-width: 130px;
  }
}
