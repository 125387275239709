.home {
  padding: 50px 0;
}

.home::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  max-width: -webkit-fill-available;
  width: 100%;
  height: 650px;
  background: linear-gradient(
      180deg,
      #251f1e 0%,
      #fb4839 30%,
      #f5cd22 60%,
      #35afa3 90%,
      #251f1e 100%
    ),
    url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100%25" height="100%25"%3E%3Cfilter id="noiseFilter"%3E%3CfeTurbulence type="fractalNoise" baseFrequency="0.5" numOctaves="3" stitchTiles="stitch"%3E%3C/feTurbulence%3E%3CfeColorMatrix type="saturate" values="0" /%3E%3C/filter%3E%3Crect width="100%25" height="100%25" filter="url(%23noiseFilter)" opacity="0.45" /%3E%3C/svg%3E');
  background-blend-mode: hard-light;
  background-size: cover;
}

.hero__swiper {
  height: 600px;
}

.hero {
  margin-top: 90px;
  display: flex;
  justify-content: flex-end;
  height: 700px;
}

.hero__data {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  max-height: 600px;
  max-width: 402px;
  width: 100%;
  padding: 25px;
  padding-bottom: 95px;
  background: #251f1eb2;
}

.hero__title {
  font-family: "Onest", sans-serif;
  font-size: 36px;
  font-weight: 800;
  line-height: 45.9px;
  color: $white;
}

.hero__album {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.07px;
  color: $white;
}

.hero__price {
  margin-bottom: 20px;
  font-family: "Onest", sans-serif;
  font-size: 36px;
  font-weight: 800;
  line-height: 45.9px;
  text-align: left;
  color: $white;
}

.hero__button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 352px;
  width: 100%;
  padding: 10px 0;
  background: transparent;
  font-family: "Onest", sans-serif;
  border: 1px solid $white;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
  cursor: pointer;
  transition: 0.3s all;
}

.hero__next {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
}

.hero__next--wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}

.hero__info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hero__info--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 262px;
  gap: 20px;
}

.hero__thumb {
  width: 815px;
  height: 600px;
  position: relative;
}

.hero__thumb:hover .hero__image--album {
  left: 50%;
  transform: translateX(-75%);
}

.hero__thumb:hover .hero__image--vinyl {
  left: 50%;
  transform: translateX(-35%);
}

.hero__image--album {
  max-width: 600px;
  width: 100%;
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: transform 0.33s ease-in-out;
}

.hero__image--vinyl {
  max-width: 600px;
  width: 100%;
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(-25deg);
  transition: transform 0.33s ease-in-out;
}

.hero__thumb:hover .hero__image--wrapper {
  left: 50%;
  transform: translateX(-90%);
}

.hero__thumb:hover .hero__image--cassette {
  left: 50%;
  transform: translateX(-25%);
}

.hero__image--wrapper {
  max-width: 600px;
  width: 100%;
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-55%);
  transition: transform 0.33s ease-in-out;
}
.hero__image--cassette {
  max-width: 600px;
  width: 100%;
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-45%);
  transition: transform 0.33s ease-in-out;
}

.hero__swipe--back,
.hero__swipe--next {
  max-width: 173.5px !important;
  width: 100% !important;
  padding: 10px !important;
  border: 1px solid $white !important;
  background-color: transparent !important;
}

.hero__swipe--back:hover {
  background: $white !important;
}

.hero__swipe--back:hover path {
  stroke: $black;
}

.hero__swipe--next:hover {
  background: $white !important;
}

.hero__swipe--next:hover path {
  stroke: $black;
}

// .hero__swipe--back:focus {
//   background: $grey2 !important;
// }

// .hero__swipe--back:focus path {
//   stroke: $white;
// }

// .hero__swipe--next:focus {
//   background: $grey2 !important;
// }

// .hero__swipe--next:focus path {
//   stroke: $white;
// }

.hero__swipe--back {
  left: 0 !important;
}

.hero__swipe--next {
  right: 0 !important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  display: none;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  display: none;
}

.hero__button--wrapper {
  width: 352px;
  height: 42px;
  position: relative;
  margin-top: auto;
}

.hero__custom--pagination {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 352px;
  gap: 3px;
}

.hero__custom--bullet {
  cursor: pointer;
  width: 100%;
  height: 1px;
  background: $grey2;
  border: transparent;
}

.hero__custom--bullet.active {
  background: $white;
}

.hero .swiper .swiper-slide {
  overflow: visible;
}

@media screen and (max-width: 1000px) {
  .hero__custom--pagination {
    max-width: 335px;
    width: 90%;
    bottom: 20%;
  }

  .home {
    padding: 70px 0 20px;
  }

  .home .title {
    display: none;
  }

  .hero {
    margin-top: 45px;
    flex-direction: column;
    height: auto;
  }

  .hero__thumb {
    max-width: 815px;
    width: 100%;
    height: 290px;
  }

  .hero__swiper {
    height: 290px;
  }

  .hero__image--wrapper {
    max-width: 250px;
    width: 100%;
    transform: translateX(-50%);
  }
  .hero__image--cassette {
    max-width: 250px;
    width: 100%;
    transform: translateX(-50%);
  }

  .hero__image--album {
    max-width: 250px;
    width: 100%;
    transform: translateX(-50%);
  }

  .hero__image--vinyl {
    max-width: 250px;
    width: 100%;
    transform: translateX(-50%);
  }

  .hero__thumb:hover .hero__image--cassette {
    transform: translateX(-50%);
  }
  .hero__thumb:hover .hero__image--album {
    transform: translateX(-50%);
  }
  .hero__thumb:hover .hero__image--wrapper {
    transform: translateX(-50%);
  }
  .hero__thumb:hover .hero__image--vinyl {
    transform: translateX(-50%);
  }

  .hero__next--wrapper,
  .hero__button--wrapper,
  .hero__custom--pagination {
    display: none;
  }

  .hero__title {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 35.7px;
  }

  .hero__album {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.4px;
  }

  .hero__price {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.4px;
  }
}
