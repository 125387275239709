@keyframes disapearing {
  0% {
    display: block;
    position: fixed;
  }

  100% {
    display: none;
  }
}

.loader__bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: $black;

  animation: disapearing 0.35s ease-in forwards;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 650px;
  background: linear-gradient(
      180deg,
      #251f1e 0%,
      #fb4839 30%,
      #f5cd22 60%,
      #35afa3 90%,
      #251f1e 100%
    ),
    url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100%25" height="100%25"%3E%3Cfilter id="noiseFilter"%3E%3CfeTurbulence type="fractalNoise" baseFrequency="0.5" numOctaves="3" stitchTiles="stitch"%3E%3C/feTurbulence%3E%3CfeColorMatrix type="saturate" values="0" /%3E%3C/filter%3E%3Crect width="100%25" height="100%25" filter="url(%23noiseFilter)" opacity="0.45" /%3E%3C/svg%3E');
  background-blend-mode: hard-light;
  background-size: cover;
}

.loader__hidden {
  display: none;
}
