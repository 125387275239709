.backdrop {
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: #151312b2;
  backdrop-filter: blur(15px);

  opacity: 1;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px;
  max-width: 794px;
  width: 100%;

  background-color: $black;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(-50%, -50%);
}

.modal__title {
  margin-top: 10px;
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.08px;
  color: $white;
}

.modal__subtitle {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
}

.modal__thumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__payment {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modal__input {
  max-width: 504px;
  width: 100%;
  height: 43px;
  padding-left: 10px;
  border: transparent;
  border-left: 1px solid $grey2;
  border-bottom: 1px solid $grey2;
  background: transparent;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
  border-radius: 0;

  &::placeholder {
    font-family: "Onest", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.95px;
    color: $grey4;
  }
}

.modal__payment--box {
  width: 20px;
  height: 20px;
  border: 1px solid $grey4;
  cursor: pointer;
}

.modal__payment--boxActive {
  background: $white;
  border: transparent;
}

.modal__line {
  margin-top: 10px;
  border-top: 1px solid $white;
  width: 100%;
}

.modal__total {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
}

.modal__total--accent {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.08px;
  color: $white;
}

.modal__tabs {
  width: 100%;
  text-align: center;

  .modal__tabs--wrapper {
    position: relative;
    display: flex;
    justify-content: space-around;

    .modal__tab {
      cursor: pointer;
      padding: 10px 20px;
      color: $grey2;
      transition: color 0.3s;
      font-family: "Onest", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22.95px;

      &.active {
        color: $white;
      }
    }

    .modal__tab--underline {
      position: absolute;
      bottom: 0;
      height: 3px;
      background: $white;
      width: 50%;
      transition: all 0.3s;

      &.ukraine {
        left: 0;
      }

      &.abroad {
        left: 50%;
      }
    }
  }
}

.modal__button {
  max-width: 282px;
  width: 100%;
  height: 43px;
  border: 1px solid $white;
  background: transparent;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
  transition: 0.3s all;
}

.modal__select--wrapper {
  max-width: 504px;
  width: 100%;
  position: relative;
}

.modal__select--list {
  position: absolute;
  top: 41.6px;
  z-index: 2;
  max-width: 437px;
  width: 100%;
  height: fit-content;
  max-height: 108px;
  padding: 5px 10px 10px 10px;
  background: $black;
  border: 1px solid $grey2;
  border-top: 0;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey2;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $grey2;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.modal__select--item {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey4;
  cursor: pointer;

  &:hover {
    color: $white;
  }
}

@media screen and (max-width: 1000px) {
  .modal {
    padding: 10px 15px;
  }

  .modal__title {
    font-size: 28px;
    line-height: 35.7px;
  }

  .modal__subtitle {
    display: none;
    font-size: 13px;
    line-height: 18.95px;
  }

  .modal__input {
    height: 38px;
    &::placeholder {
      font-size: 16px;
      line-height: 20.4px;
    }
  }

  .modal__tabs .modal__tabs--wrapper .modal__tab {
    font-size: 16px;
    line-height: 20.4px;
  }

  .modal__total--accent {
    font-size: 28px;
    line-height: 35.7px;
  }

  .modal__total {
    font-size: 16px;
    line-height: 20.4px;
  }
}
