$white: #ffffff;
$green: #35afa3;
$red: #fb4839;
$red2: #ff7d72;
$yellow: #f5cd22;
$black: #251f1e;
$black2: #151312;
$grey: #9a8784;
$grey2: #c8bebc;
$grey3: #d1c8c7;
$grey4: #5b4e4c;
$grey5: #ede9e9;
