.delivery {
  padding: 50px 0;
}

.delivery::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  max-width: -webkit-fill-available;
  width: 100%;
  height: 650px;
  background: linear-gradient(
      180deg,
      #251f1e 0%,
      #fb4839 30%,
      #f5cd22 60%,
      #35afa3 90%,
      #251f1e 100%
    ),
    url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100%25" height="100%25"%3E%3Cfilter id="noiseFilter"%3E%3CfeTurbulence type="fractalNoise" baseFrequency="0.5" numOctaves="3" stitchTiles="stitch"%3E%3C/feTurbulence%3E%3CfeColorMatrix type="saturate" values="0" /%3E%3C/filter%3E%3Crect width="100%25" height="100%25" filter="url(%23noiseFilter)" opacity="0.45" /%3E%3C/svg%3E');
  background-blend-mode: hard-light;
  background-size: cover;
}

.delivery__item {
  max-width: 600px;
  width: 100%;
  padding: 30px;
  background: #251f1eb2;
}

.delivery__title {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.07px;
  color: $white;
}

.delivery__wrapper {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.delivery__thumb {
  display: flex;
  gap: 40px;
  margin-top: 40px;
}

.delivery__text {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
}

.delivery__text--wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.delivery__info {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  margin: 50px auto 0;
  text-align: center;
  gap: 20px;
}

.delivery__info--title {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.07px;
  text-align: center;
  color: $white;
}

.delivery__info--text {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  text-align: center;
  color: $grey2;
}

@media screen and (max-width: 1000px) {
  .delivery {
    padding: 70px 0 20px;
  }

  .delivery__thumb {
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }

  .delivery__item {
    max-width: 350px;
    padding: 20px;
  }
}
