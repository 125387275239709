.cart {
  padding: 50px 0;
}

.cart::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  max-width: -webkit-fill-available;
  width: 100%;
  height: 650px;
  background: linear-gradient(
      180deg,
      #251f1e 0%,
      #fb4839 30%,
      #f5cd22 60%,
      #35afa3 90%,
      #251f1e 100%
    ),
    url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100%25" height="100%25"%3E%3Cfilter id="noiseFilter"%3E%3CfeTurbulence type="fractalNoise" baseFrequency="0.5" numOctaves="3" stitchTiles="stitch"%3E%3C/feTurbulence%3E%3CfeColorMatrix type="saturate" values="0" /%3E%3C/filter%3E%3Crect width="100%25" height="100%25" filter="url(%23noiseFilter)" opacity="0.45" /%3E%3C/svg%3E');
  background-blend-mode: hard-light;
  background-size: cover;
}

.cart__bill {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 300px;
  width: 100%;
  height: fit-content;
  padding: 30px;
  background: #251f1eb2;
  backdrop-filter: blur(20px);
}

.cart__bill--title {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.08px;
  color: $white;
}

.cart__bill--line {
  max-width: 240px;
  width: 100%;
  height: 1px;
  background: #ffffff;
}

.cart__bill--total {
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $grey2;
}

.cart__bill--sum {
  font-family: "Onest", sans-serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 42.08px;
  color: $white;
}

.cart__bill--button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 240px;
  width: 100%;
  height: 43px;
  background: transparent;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  color: $white;
  border: 1px solid $white;
  transition: 0.3s all;
}

.cart__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 40px;
}

.cart__top--wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.cart__top--quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Onest", sans-serif;
  font-size: 16.07px;
  font-weight: 400;
  line-height: 20.49px;
  color: $white;
}

.cart__item--wrapper {
  padding: 0 9px;
}

.cart__quantity--wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.cart__item {
  max-width: 300px;
  width: 100%;
  padding-bottom: 10px;
  box-shadow: 0px 4px 4px 0px #00000040;

  backdrop-filter: blur(30px);
  background: #251f1e4d;
  border: 1px solid #ffffff4d;
}

.cart__item--image {
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
}

.cart__item--merch {
  max-width: 300px;
  width: 100%;
  height: 275px;
}

.cart__merch--wrapper {
  margin-top: 7px;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.cart__merch--size {
  font-family: "Onest", sans-serif;
  font-size: 13.32px;
  font-weight: 400;
  line-height: 16.99px;
  text-align: center;
  color: $white;
}

.cart__merch--sizeColor {
  font-family: "Onest", sans-serif;
  font-size: 13.32px;
  font-weight: 400;
  line-height: 16.99px;
  text-align: center;
  color: $white;
}

.cart__merch--thumb {
  display: flex;
  align-items: center;
  gap: 5px;
}

.cart__item--box {
  width: 15px;
  height: 15px;
  background-color: $white;
}

.cart__merch--sizeActive {
  font-family: "Onest", sans-serif;
  font-size: 13.32px;
  font-weight: 700;
  line-height: 16.99px;
  text-align: center;
  color: $white;
}

.cart__item--artistName {
  color: $white;
  margin-top: 15px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.95px;
  text-align: center;
}

.cart__item--infoTop {
  color: $white;
  margin-top: 10px;
  font-family: "Onest", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.95px;
  text-align: center;
}

.cart__item--albumName {
  font-family: "Onest", sans-serif;
  color: $white;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  text-align: center;
}

.cart__item--infoBottom {
  font-family: "Onest", sans-serif;
  color: $white;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.95px;
  text-align: center;
}

.cart__item--price {
  font-family: "Onest", sans-serif;
  font-size: 24.43px;
  font-weight: 600;
  line-height: 31.15px;
  color: $white;
  text-align: center;
  margin-top: 7px;
}

.cart__cursor--pointer {
  cursor: pointer;
}

.cart__cursor--pointer:hover {
  fill: $white;
}

.cart__cursor--pointer:hover path {
  fill: $black;
}

.cart__cross--icon {
  cursor: pointer;
  &:hover {
    fill: $white;
  }
}

.cart__item--menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .cart {
    padding: 70px 0;
  }

  .cart__item {
    max-width: 350px;
  }

  .cart__wrapper {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 20px;
  }

  .cart__bill {
    max-width: 350px;
    background: $black2;
  }

  .cart__bill--button {
    max-width: 290px;
    height: 40px;
    border: 1px solid $white;
    background: transparent;
    font-family: "Onest", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.4px;
    color: $white;
  }

  .cart__cursor--pointer {
    width: 20px;
    height: 20px;
  }

  .cart__top--quantity {
    font-family: "Onest", sans-serif;
    color: $white;
    font-size: 16.07px;
    font-weight: 400;
    line-height: 20.49px;
  }

  .cart__item--wrapper {
    margin-top: 5px;
    padding-left: 10px;
    display: flex;
    gap: 10px;
  }

  .cart__item--image {
    width: 150px;
    height: 150px;
  }

  .cart__item--merch {
    width: 150px;
    height: 150px;
  }

  .cart__item--artistName {
    text-align: start;
  }

  .cart__item--albumName {
    text-align: start;
  }

  .cart__item--thumb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
